.main-div-educational {
    display: flex;
    flex-direction: column;
    padding: 2rem 0 2rem 0;
  
    .title {
      text-align: center;
      width: 100%;
      h3 {
        margin-top: 1rem;
        margin-bottom: 3rem;
        color: $theme-color;
        font-size: 30px;
      }
      p {
        font-size: 20px;
        margin-top: 2rem;
      }
    }
    .parallax {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-items: center;
        text-align: center;
        h5{
          color: $theme-color;
          font-size: 1em;
          line-height: 1.5rem;
          width: 100%;
          @media #{$xs-layout} {
            font-size: 1em;
            line-height: 2rem;
            padding: 2rem;
         
            }
      }
        h4{
            color: #fff;
            font-size: 2em;
            line-height: 3rem;
            width: 100%;
            @media #{$xs-layout} {
                font-size: 1.5em;
                line-height: 2rem;
              }
        }
        /* The image used */
      
        /* Set a specific height */
        height: 200px;
        width: 100%;
      
        /* Create the parallax scrolling effect */
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
      .text{
        padding: 2rem 3rem 2rem 3rem;
          h4{
            margin-top: 1rem;
              margin-bottom: 3rem;
              color: $theme-color;
              font-size: 2em;
              text-align: center;
              @media #{$xs-layout} {
                line-height: 2rem;
              }
          }
          p{
              font-size: 1.5em;
          }
      }
  }
  