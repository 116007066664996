/*----- 3. Slider style  ------*/

.slider-height-1 {
    height: 750px;
    @media #{$xl-layout} {
      height: 650px;
    }
    @media #{$lg-layout} {
      height: 520px;
    }
    @media #{$md-layout} {
      height: 450px;
    }
    @media #{$xs-layout} {
      height: auto;
      padding: 50px 0 0;
      &.slider-height-res15 {
        padding: 0 0 0;
      }
    }
    @media #{$sm-layout} {
      padding: 38px 0 0;
    }
    &.slider-height-res {
      @media #{$xs-layout} {
        height: 400px;
        position: relative;
        &:before {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          content: "";
          background-color: #fff;
          opacity: 0.5;
        }
      }
    }
  }
  
  .single-slider {
      background-image: url('/assets/img/banner/home-bg.jpg');
      background-size: cover;
      background-position: center;
    display: flex;
    align-items: flex-end;
    @media #{$xs-layout} {
      padding-top: 5rem;
    }
    .slider-content {
      padding: 183px 0 0;
      @media #{$xl-layout} {
        padding: 80px 0 0;
      }
      @media #{$lg-layout} {
        padding: 45px 0 0;
      }
      @media #{$md-layout} {
        padding: 0 0 0;
      }
      @media #{$xs-layout} {
        padding: 0 0 40px;
        text-align: center;
        .slider-btn{
          text-align: start;
        }
      }
      @media #{$sm-layout} {
        padding: 0 0 0;
        text-align: left;
      }
      h3 {
        font-size: 40px;
        line-height: 64px;
        color: #fff;
        text-align: left;
        @media #{$xs-layout} {
          font-size: 20px;
          line-height: 35px;
        }
      }
      h1 {
        font-weight: normal;
        font-size: 28px;
        text-align: left;
        color: $theme-color;
  
        margin: 50px 0 37px;
        @media #{$lg-layout} {
          line-height: 65px;
          font-size: 52px;
        }
        @media #{$md-layout} {
          line-height: 52px;
          font-size: 40px;
          margin: 6px 0 20px;
        }
        @media #{$xs-layout} {
          line-height: 30px;
      font-size: 20px;
      margin: 6px 0 20px;
        }
        @media #{$sm-layout} {
          font-size: 30px;
          margin: 6px 0 10px;
        }
      }
      .slider-btn {
        a {
          font-size: 18px;
          font-weight: bold;
          color: $theme-color;
          display: inline-block;
          background-color: $secundary-color;
          border: 1px solid $secundary-color;
          text-transform: uppercase;
          line-height: 1;
          padding: 19px 40px 21px;
          @media #{$md-layout} {
            padding: 15px 35px 17px;
          }
          @media #{$xs-layout} {
            padding: 15px 35px 17px;
            text-align: initial!important;
            align-self: start;
          }
          &:hover {
            border: 1px solid $secundary-color;
            color: #fff;
          }
        }
      }
      &.slider-content-10 {
        padding-top: 0;
        @media #{$xs-layout} {
          text-align: center;
          display: block;
          width: 100%;
            padding-top: 5rem;
        }
        @media #{$sm-layout} {
          text-align: left;
        }
      }
    }
    .slider-single-img {
      margin: 0 -80px 0 -80px;
      @media #{$xl-layout} {
        margin: 0 0 0 0;
      }
      @media #{$lg-layout} {
        margin: 0 0 0 0;
      }
      @media #{$md-layout} {
        margin: 0 0 0 0;
      }
      @media #{$xs-layout} {
        margin: 0 0 0 0;
      }
    }
    &.single-slider-10 {
      align-items: center;
    }
  }
  
  .swiper-slide .slider-content * {
    -webkit-animation-duration: 1.3s;
    animation-duration: 1.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  
  .swiper-slide-active {
    .slider-animated-1 {
      h3 {
        -webkit-animation-delay: 1s;
        animation-delay: 1s;
        -webkit-animation-name: fadeInUp;
        animation-name: fadeInUp;
      }
      h1 {
        -webkit-animation-delay: 1.3s;
        animation-delay: 1.3s;
        -webkit-animation-name: fadeInUp;
        animation-name: fadeInUp;
      }
      h5 {
        -webkit-animation-delay: 1.5s;
        animation-delay: 1.5s;
        -webkit-animation-name: fadeInUp;
        animation-name: fadeInUp;
      }
      p {
        -webkit-animation-delay: 1.5s;
        animation-delay: 1.5s;
        -webkit-animation-name: fadeInUp;
        animation-name: fadeInUp;
      }
      a {
        -webkit-animation-delay: 1.6s;
        animation-delay: 1.6s;
        -webkit-animation-name: fadeInUp;
        animation-name: fadeInUp;
      }
      &.slider-single-img img {
        -webkit-animation-delay: 1.6s;
        animation-delay: 1.6s;
        -webkit-animation-name: fadeInUp;
        animation-name: fadeInUp;
      }
      &.single-slider-img4 img {
        -webkit-animation-delay: 1.6s;
        animation-delay: 1.6s;
        -webkit-animation-name: fadeInUp;
        animation-name: fadeInUp;
      }
      &.slider-singleimg-hm9 img {
        -webkit-animation-delay: 1.6s;
        animation-delay: 1.6s;
        -webkit-animation-name: fadeInUp;
        animation-name: fadeInUp;
      }
      &.slider-singleimg-hm11 img {
        -webkit-animation-delay: 1.6s;
        animation-delay: 1.6s;
        -webkit-animation-name: fadeInUp;
        animation-name: fadeInUp;
      }
    }
    .slider-animated-2 {
      h3 {
        -webkit-animation-delay: 1s;
        animation-delay: 1s;
        -webkit-animation-name: fadeInLeft;
        animation-name: fadeInLeft;
      }
      h1 {
        -webkit-animation-delay: 1.3s;
        animation-delay: 1.3s;
        -webkit-animation-name: fadeInLeft;
        animation-name: fadeInLeft;
      }
      a {
        -webkit-animation-delay: 1.6s;
        animation-delay: 1.6s;
        -webkit-animation-name: fadeInLeft;
        animation-name: fadeInLeft;
      }
      &.slider-singleimg-hm10 img {
        -webkit-animation-delay: 1.6s;
        animation-delay: 1.6s;
        -webkit-animation-name: fadeInRight;
        animation-name: fadeInRight;
      }
    }
  }
  
  .nav-style-1 {
    .ht-swiper-button-nav {
      width: 80px;
      height: 80px;
      padding: 0;
      color: #999;
      display: inline-block;
      font-size: 80px;
      left: 80px;
      opacity: 0;
      visibility: hidden;
      position: absolute;
      text-align: center;
      top: 50%;
      transform: translateY(-50%);
      transition: all 0.3s ease 0s;
      background: none;
      border: none;
      @media #{$xl-layout} {
        left: 30px;
      }
      @media #{$lg-layout} {
        left: 30px;
      }
      @media #{$md-layout} {
        left: 15px;
        font-size: 60px;
      }
      @media #{$xs-layout} {
        left: 15px;
        font-size: 50px;
      }
      &:hover {
        color: $theme-color;
      }
      &.swiper-button-next {
        left: auto;
        right: 80px;
        @media #{$xl-layout} {
          right: 30px;
        }
        @media #{$lg-layout} {
          right: 30px;
        }
        @media #{$md-layout} {
          right: 15px;
        }
        @media #{$xs-layout} {
          right: 15px;
        }
      }
    }
  
    &.nav-testi-style {
      .owl-nav {
        div {
          font-size: 50px;
          left: -155px;
          color: #8d8c8c;
          @media #{$xl-layout} {
            left: -80px;
          }
          @media #{$lg-layout} {
            left: -80px;
          }
          @media #{$md-layout} {
            left: -30px;
          }
          @media #{$xs-layout} {
            display: none;
          }
          &:hover {
            color: #3d6882;
          }
          &.owl-next {
            right: -155px;
            left: auto;
            @media #{$xl-layout} {
              right: -80px;
            }
            @media #{$lg-layout} {
              right: -80px;
            }
            @media #{$md-layout} {
              right: -30px;
            }
          }
        }
      }
      &.nav-testi-mrg {
        .owl-nav {
          div {
            left: -80px;
            color: #fff;
            @media #{$md-layout} {
              left: 0px;
            }
            @media #{$xs-layout} {
              display: none;
            }
            &.owl-next {
              right: -80px;
              left: auto;
              @media #{$md-layout} {
                right: 0px;
                left: auto;
              }
              @media #{$xs-layout} {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  .slider-area {
    &:hover .nav-style-1 .ht-swiper-button-nav {
      opacity: 1;
      visibility: visible;
    }
    &.ml-10 {
      margin-left: 0;
    }
    @media #{$xl-layout} {
      &.ml-70 {
        margin-left: 15px;
      }
      &.mr-70 {
        margin-right: 15px;
      }
    }
    @media #{$lg-layout} {
      &.ml-70 {
        margin-left: 30px;
      }
      &.mr-70 {
        margin-right: 30px;
      }
    }
    @media #{$md-layout} {
      &.ml-70 {
        margin-left: 40px;
      }
      &.mr-70 {
        margin-right: 40px;
      }
    }
    @media #{$xs-layout} {
      &.ml-70 {
        margin-left: 15px;
      }
      &.mr-70 {
        margin-right: 15px;
      }
    }
  
    .swiper-pagination-bullets {
      bottom: 26px;
      left: 0;
      position: absolute;
      right: 0;
      text-align: center;
      .swiper-pagination-bullet {
        background: #fff none repeat scroll 0 0;
        opacity: 1;
        border-radius: 100%;
        display: inline-block;
        height: 14px;
        margin: 0 8px;
        width: 14px;
        position: relative;
        &:before {
          position: absolute;
          content: "";
          left: -3px;
          right: -3px;
          top: -3px;
          bottom: -3px;
          border: 1px solid transparent;
          border-radius: 100%;
        }
        &-active:before {
          border: 1px solid #fff;
        }
      }
    }
  }
  
  /* slider style-2 */
  .slider-content-2 {
    margin-top: -6px;
    @media #{$xs-layout} {
      text-align: center;
    }
    h3 {
      color: #000000;
      font-size: 24px;
      font-weight: 500;
      position: relative;
      margin-left: 135px;
      margin-bottom: 0;
      @media #{$xs-layout} {
        margin-left: 0px;
      }
      @media #{$sm-layout} {
        margin-left: 60px;
      }
      &::before {
        position: absolute;
        left: -135px;
        top: 14px;
        content: "";
        width: 120px;
        height: 2px;
        background-color: #000000;
        @media #{$xs-layout} {
          display: none;
        }
        @media #{$sm-layout} {
          display: block;
          left: 80px;
          width: 60px;
        }
      }
  
      &.no-style {
        margin-left: 0;
        @media #{$sm-layout} {
          margin-left: 0;
        }
        &:before {
          display: none;
        }
      }
    }
    h1 {
      font-size: 72px;
      line-height: 97px;
      color: #010101;
      margin: 6px 0 37px;
      @media #{$xl-layout} {
        font-size: 60px;
        line-height: 80px;
      }
      @media #{$lg-layout} {
        font-size: 56px;
        line-height: 70px;
      }
      @media #{$md-layout} {
        font-size: 45px;
        line-height: 60px;
      }
      @media #{$xs-layout} {
        font-size: 35px;
        line-height: 45px;
      }
    }
    .slider-btn {
      a {
        font-size: 16px;
        color: #010101;
        display: inline-block;
        border: 1px solid #333;
        text-transform: uppercase;
        line-height: 1;
        padding: 19px 50px 21px;
        background-color: #eeeff1;
        @media #{$xs-layout} {
          padding: 15px 35px 17px;
          text-align: initial;
        }
        &:hover {
          border: 1px solid $theme-color;
          color: #fff;
        }
      }
  
      &--style2 {
        a {
          background-color: transparent;
        }
      }
    }
    &.slider-content-fruits {
      h3 {
        color: #6eab49;
        &::before {
          background-color: #6eab49;
          @media #{$sm-layout} {
            left: 45px;
          }
        }
      }
      h1 {
        font-size: 71px;
        @media #{$xl-layout} {
          font-size: 55px;
        }
        @media #{$lg-layout} {
          font-size: 50px;
        }
        @media #{$md-layout} {
          font-size: 40px;
        }
        @media #{$xs-layout} {
          font-size: 30px;
          margin: 6px 0 20px;
        }
      }
      .slider-btn {
        a {
          font-size: 16px;
          color: #fff;
          border: 1px solid #6eab49;
          background-color: #6eab49;
          &:hover {
            border: 1px solid $theme-color;
            color: #fff;
          }
        }
      }
      &--style2 {
        h3 {
          color: #ab87cd;
          &:before {
            background-color: #ab87cd;
            @media #{$sm-layout} {
              left: 45px;
            }
          }
        }
        .slider-btn {
          a {
            border: 1px solid #ab87cd;
            background-color: #ab87cd;
          }
        }
      }
    }
  
    &--style2 {
      h1 {
        margin-bottom: 5px;
      }
      p {
        font-size: 17px;
        font-weight: 400;
        color: #73848e;
        margin-bottom: 50px;
      }
      .slider-btn {
        a {
          background-color: transparent;
        }
      }
  
      &--white {
        h1 {
          color: $white;
        }
        h3 {
          color: $white;
        }
        .slider-btn {
          a {
            color: $white;
            border-color: $white;
          }
        }
      }
    }
  
    &--white {
      h1 {
        color: $white;
      }
      h3 {
        color: $white;
      }
      p {
        color: $white;
      }
      .slider-btn {
        a {
          color: $white;
          background-color: transparent;
          border-color: $white;
        }
      }
    }
  }
  
  .single-slider-2 {
    @media #{$xl-layout} {
      background-position: 50%;
    }
    @media #{$lg-layout} {
      background-position: 42%;
    }
    @media #{$xs-layout} {
      background-position: 30%;
    }
    @media #{$sm-layout} {
      background-position: 22%;
    }
  }
  
  /* home 3 */
  
  .slider-height-2 {
    height: 800px;
    @media #{$xl-layout} {
      height: 570px;
    }
    @media #{$lg-layout} {
      height: 500px;
    }
    @media #{$md-layout} {
      height: 500px;
    }
    @media #{$xs-layout} {
      height: 400px;
    }
    &.slider-content-center {
      display: flex;
      align-items: center;
    }
  }
  
  .slider-content-3 {
    h3 {
      font-size: 24px;
      font-weight: 500;
      margin: 0;
      position: relative;
      display: inline-block;
      &::before {
        position: absolute;
        left: -110px;
        top: 16px;
        content: "";
        width: 80px;
        height: 2px;
        background-color: #000000;
      }
      &::after {
        position: absolute;
        right: -110px;
        top: 16px;
        content: "";
        width: 80px;
        height: 2px;
        background-color: #000000;
      }
    }
    h1 {
      font-size: 72px;
      line-height: 56px;
      margin: 28px 0 23px;
      @media #{$lg-layout} {
        font-size: 52px;
        line-height: 40px;
      }
      @media #{$md-layout} {
        font-size: 52px;
        line-height: 40px;
      }
      @media #{$xs-layout} {
        font-size: 35px;
        line-height: 30px;
        margin: 18px 0 13px;
      }
    }
    p {
      font-size: 18px;
      color: #010101;
      margin: 0 0 44px;
    }
    .slider-btn {
      a {
        font-size: 16px;
        color: #010101;
        display: inline-block;
        border: 1px solid #333;
        text-transform: uppercase;
        line-height: 1;
        padding: 19px 50px 21px;
        &:hover {
          border: 1px solid $theme-color;
          color: #fff;
        }
      }
    }
    &.slider-content-4 {
      margin-left: 112px;
      @media #{$xx-layout} {
        margin-left: 30px;
      }
      @media #{$xl-layout} {
        margin-left: 0px;
      }
      @media #{$lg-layout} {
        margin-left: 0px;
      }
      @media #{$md-layout} {
        margin-left: 0px;
      }
      @media #{$xs-layout} {
        margin-left: 0px;
      }
      h1 {
        @media #{$md-layout} {
          font-size: 34px;
          line-height: 35px;
          margin: 15px 0 15px;
        }
      }
      h3 {
        &:before {
          display: none;
        }
        &:after {
          display: none;
        }
      }
    }
  }
  
  /* slider hm4 */
  
  .slider-height-3 {
    height: 710px;
    & .container {
      margin-right: inherit;
      margin-left: inherit;
    }
    @media #{$xl-layout} {
      height: 570px;
    }
    @media #{$lg-layout} {
      height: 500px;
    }
    @media #{$md-layout} {
      height: 500px;
    }
    @media #{$xs-layout} {
      height: 400px;
    }
    &.slider-height-res-hm4 {
      @media #{$xs-layout} {
        height: 680px;
      }
    }
    &.slider-height-res-hm4 {
      @media #{$sm-layout} {
        height: 400px;
      }
    }
  }
  
  .single-slider-img4 {
    position: relative;
    margin: 0 -77px 0 50px;
    @media #{$xl-layout} {
      margin: 0 0px 0 0px;
    }
    @media #{$lg-layout} {
      margin: 0 0px 0 0px;
    }
    @media #{$md-layout} {
      margin: 0 0px 0 0px;
    }
    @media #{$xs-layout} {
      margin: 30px 0 0;
    }
    .single-price-wrap {
      position: absolute;
      top: 0;
      right: -24px;
      animation-iteration-count: infinite;
      animation-name: bounce-img;
      animation-duration: 2.9s;
      width: 130px;
      @media #{$xl-layout} {
        right: 0px;
      }
      @media #{$lg-layout} {
        right: 0px;
      }
      @media #{$md-layout} {
        right: 0px;
      }
      @media #{$xs-layout} {
        right: 0px;
      }
      img {
        width: 100%;
      }
      .single-price {
        position: absolute;
        top: 0;
        left: 50%;
        margin-top: 26px;
        transform: translateX(-50%);
        text-align: center;
        span {
          display: block;
          line-height: 1;
          font-size: 24px;
          color: #915342;
          font-weight: 600;
          &.dollar {
            margin-top: 2px;
          }
        }
      }
    }
  }
  
  /* slider 5 */
  
  .slider-height-4 {
    height: 750px;
    @media #{$xx-layout} {
      height: 600px;
    }
    @media #{$xl-layout} {
      height: 600px;
    }
    @media #{$lg-layout} {
      height: 550px;
    }
    @media #{$md-layout} {
      height: 530px;
    }
    @media #{$xs-layout} {
      height: 500px;
    }
  }
  .slider-content-5 {
    padding-top: 66px;
    h3 {
      font-weight: 500;
      letter-spacing: 0.5px;
      font-size: 30px;
      margin: 0;
      color: $theme-color;
    }
    h1 {
      font-weight: 500;
      font-size: 72px;
      margin: 27px 0 22px;
      letter-spacing: 0.5px;
      color: $theme-color;
      line-height: 56px;
      @media #{$md-layout} {
        font-size: 58px;
        margin: 17px 0 20px;
      }
      @media #{$xs-layout} {
        font-size: 45px;
        margin: 15px 0 15px;
        line-height: 38px;
      }
    }
    p {
      font-size: 18px;
      color: #fff;
      margin: 0 0 45px;
    }
  }
  .slider-btn-5 {
    a {
      font-size: 16px;
      color: #010101;
      background-color: #fff;
      display: inline-block;
      line-height: 1;
      padding: 17px 40px 18px;
      transition: all 0.3s ease-in-out 0s;
      &:hover {
        border: none;
        color: #fff;
      }
    }
  }
  .nav-style-2.owl-carousel > .owl-nav div,
  .nav-style-2 .ht-swiper-button-nav {
    color: #000;
    display: inline-block;
    font-size: 60px;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.3s ease 0s;
    width: 65px;
    height: 84px;
    line-height: 94px;
    padding: 0;
    background: none;
    border: none;
    background-color: #fff;
    @media #{$lg-layout} {
      width: 45px;
      height: 64px;
      line-height: 74px;
      font-size: 45px;
    }
    @media #{$md-layout} {
      width: 45px;
      height: 64px;
      line-height: 74px;
      font-size: 45px;
    }
    @media #{$xs-layout} {
      width: 35px;
      height: 50px;
      line-height: 54px;
      font-size: 30px;
    }
    &:hover {
      color: #fff;
      background-color: $theme-color;
    }
    &.owl-next {
      left: auto;
      right: 0px;
    }
  
    &.swiper-button-prev {
      left: 0;
    }
    &.swiper-button-next {
      right: 0;
    }
  }
  
  .slider-area {
    &:hover .nav-style-2.owl-carousel > .owl-nav div,
    &:hover .nav-style-2 .ht-swiper-button-nav {
      opacity: 1;
      visibility: visible;
    }
    &.ml-10 {
      margin-left: 0;
    }
  }
  
  /* slider 6 hm7 */
  
  .slider-height-5 {
    height: 850px;
    @media #{$xx-layout} {
      height: 600px;
    }
    @media #{$xl-layout} {
      height: 600px;
    }
    @media #{$lg-layout} {
      height: 550px;
    }
    @media #{$md-layout} {
      height: 530px;
    }
    @media #{$xs-layout} {
      height: 500px;
    }
  }
  
  .slider-content-6 {
    h1 {
      font-weight: 500;
      font-size: 60px;
      letter-spacing: 8px;
      color: #fff;
      margin: 0px;
      line-height: 48px;
      @media #{$lg-layout} {
        font-size: 45px;
      }
      @media #{$md-layout} {
        font-size: 45px;
      }
      @media #{$xs-layout} {
        font-size: 30px;
        line-height: 38px;
      }
    }
    p {
      margin: 32px 0 35px;
      font-size: 18px;
      color: #ffffff;
      letter-spacing: 1px;
      @media #{$lg-layout} {
        margin: 22px 0 30px;
      }
      @media #{$md-layout} {
        margin: 22px 0 30px;
      }
      @media #{$xs-layout} {
        margin: 18px 0 25px;
      }
    }
  }
  
  .nav-style-3.owl-carousel > .owl-nav,
  .nav-style-3 .ht-swiper-button-nav {
    position: absolute;
    right: 0;
    bottom: 0;
    left: auto;
    top: auto;
    color: #000;
    border: none;
    display: inline-block;
    font-size: 60px;
    text-align: center;
    transition: all 0.3s ease 0s;
    width: 65px;
    height: 84px;
    line-height: 94px;
    background-color: #fff;
    &.swiper-button-prev {
      right: 50px;
    }
    @media #{$xs-layout} {
      width: 50px;
      height: 60px;
      font-size: 45px;
      line-height: 67px;
    }
    &:hover {
      background-color: $theme-color;
      color: #fff;
    }
  }
  
  /* slider hm8 */
  
  .slider-height-6 {
    height: 543px;
    @media #{$lg-layout} {
      height: 500px;
    }
    @media #{$md-layout} {
      height: 400px;
    }
    @media #{$xs-layout} {
      height: 400px;
    }
  }
  
  .slider-hm8 {
    .slider-content-5 {
      padding-top: 0px;
    }
    p {
      margin: 0 0 0px;
    }
  }
  
  /* slider hm9 */
  .slider-height-7 {
    height: 950px;
    @media #{$lg-layout} {
      height: 800px;
    }
    @media #{$md-layout} {
      height: 600px;
    }
    @media #{$xs-layout} {
      height: auto;
      padding: 130px 0 100px;
    }
  }
  .slider-h9-mrg {
    margin-top: -35px;
    @media #{$lg-layout} {
      margin-top: 0px;
    }
    @media #{$md-layout} {
      margin-top: 30px;
    }
    @media #{$xs-layout} {
      margin-top: 0px;
    }
  }
  .slider-content-7 {
    @media #{$xs-layout} {
      text-align: left;
      width: 180px;
      max-width: 100%;
      margin-left: 30px;
    }
  
    h3 {
      font-size: 16px;
      font-weight: 500;
      color: #313131;
      margin: 0;
      letter-spacing: 0.3px;
      line-height: 1;
    }
  
    h1 {
      font-size: 48px;
      font-weight: 500;
      color: #313131;
      margin: 17px 0 37px;
      line-height: 65px;
      letter-spacing: 1px;
      @media #{$lg-layout} {
        font-size: 42px;
        line-height: 60px;
      }
      @media #{$md-layout} {
        font-size: 32px;
        margin: 6px 0 22px;
        line-height: 50px;
      }
      @media #{$xs-layout} {
        font-size: 22px;
        margin: 6px 0 22px;
        line-height: 38px;
      }
    }
    .slider-btn-9 a {
      font-size: 16px;
      color: #010101;
      display: inline-block;
      border: 1px solid #333;
      text-transform: uppercase;
      line-height: 1;
      padding: 19px 50px 19px;
      @media #{$md-layout} {
        padding: 14px 30px 14px;
        font-size: 15px;
      }
      @media #{$xs-layout} {
        padding: 15px 30px 15px;
        font-size: 15px;
      }
      &:hover {
        color: #fff;
        border: 1px solid $theme-color;
      }
    }
    &.slider-content-mrg-hm17 {
      margin-top: 127px;
      width: 590px;
      max-width: 100%;
      @media #{$lg-layout} {
        width: 390px;
      }
      @media #{$md-layout} {
        width: 290px;
        margin-top: 0;
      }
      @media #{$xs-layout} {
        margin-top: 0;
      }
    }
  }
  
  .slider-singleimg-hm9 {
    @media #{$xs-layout} {
      margin-top: 30px;
      &.ml-100 {
        margin-left: 20px;
      }
      &.mr-100 {
        margin-right: 20px;
      }
    }
    @media #{$sm-layout} {
      margin-top: 0;
    }
    @media #{$md-layout} {
      &.ml-100 {
        margin-left: 40px;
      }
      &.mr-100 {
        margin-right: 40px;
      }
    }
    @media #{$lg-layout} {
      &.ml-100 {
        margin-left: 70px;
      }
      &.mr-100 {
        margin-right: 70px;
      }
    }
  }
  
  /* slider hm10 */
  
  .slider-height-8 {
    height: 840px;
    @media #{$xl-layout} {
      height: 650px;
    }
    @media #{$lg-layout} {
      height: 520px;
    }
    @media #{$md-layout} {
      height: 450px;
    }
    @media #{$xs-layout} {
      height: auto;
      padding: 60px 0;
    }
  }
  
  .slider-singleimg-hm10 {
    @media #{$md-layout} {
      &.ml-40 {
        margin-left: 0;
      }
      &.mr-40 {
        margin-right: 0;
      }
    }
    @media #{$xs-layout} {
      display: flex;
      flex-direction: row;
      justify-content: center;
      img{
        width: 15rem;
      }
      &.ml-40 {
        margin-left: 0;
      }
      &.mr-40 {
        margin-right: 0;
      }
    }
    @media #{$lg-layout} {
      img{
        width: 25rem;
      }
    }
  }
  
  /* home 11 */
  
  .slider-height-9 {
    height: 780px;
    @media #{$xl-layout} {
      height: 700px;
    }
    @media #{$lg-layout} {
      height: 630px;
    }
    @media #{$md-layout} {
      height: 500px;
    }
    @media #{$xs-layout} {
      height: auto;
      padding: 130px 0 70px;
    }
    @media #{$sm-layout} {
      padding: 130px 0 70px;
    }
  }
  
  .slider-content-11 {
    h3 {
      font-size: 24px;
      font-weight: 500;
      margin: 0;
      letter-spacing: 1px;
      @media #{$md-layout} {
        font-size: 20px;
      }
      @media #{$xs-layout} {
        font-size: 20px;
      }
    }
    h1 {
      font-size: 72px;
      margin: 0;
      line-height: 97px;
      color: #010101;
      margin: 15px 0 38px;
      @media #{$lg-layout} {
        margin: 5px 0 27px;
        font-size: 52px;
        line-height: 65px;
      }
      @media #{$md-layout} {
        margin: 3px 0 20px;
        font-size: 45px;
        line-height: 55px;
      }
      @media #{$xs-layout} {
        margin: 3px 0 20px;
        font-size: 40px;
        line-height: 50px;
      }
    }
    .slider-btn-11 {
      a {
        font-size: 16px;
        color: #010101;
        display: inline-block;
        border: 1px solid #333;
        text-transform: uppercase;
        line-height: 1;
        padding: 19px 50px 19px;
        &:hover {
          border: 1px solid #a749ff;
          color: #fff;
        }
        @media #{$md-layout} {
          padding: 15px 35px 15px;
        }
        @media #{$xs-layout} {
          padding: 12px 30px 12px;
          font-size: 14px;
        }
      }
    }
    @media #{$xs-layout} {
      text-align: center;
    }
    @media #{$xs-layout} {
      &.fullpage-content {
        text-align: center;
        width: 100%;
        h3 {
          font-size: 16px;
          letter-spacing: 0;
        }
        h1 {
          font-size: 30px;
          line-height: 40px;
        }
      }
    }
    @media #{$sm-layout} {
      &.fullpage-content {
        h3 {
          font-size: 17px;
          letter-spacing: 0;
        }
        h1 {
          font-size: 35px;
        }
      }
    }
  }
  
  .slider-singleimg-hm11 {
    margin: 0 22px 0 28px;
    @media #{$xl-layout} {
      margin: 0 50px 0 60px;
    }
    @media #{$xs-layout} {
      margin: 30px 0px 0 0px;
    }
    @media #{$sm-layout} {
      margin: 0px 0px 0 0px;
    }
  }
  
  .slider-h11-mrg {
    margin-bottom: -140px;
    @media #{$xs-layout, $md-layout} {
      margin-bottom: 0;
    }
  }
  
  /* home 12 */
  
  #section2 .fp-tableCell,
  #section4 .fp-tableCell {
    display: flex;
    align-items: center;
  }
  #section1 .fp-tableCell,
  #section3 .fp-tableCell {
    display: flex;
    align-items: flex-end;
  }
  
  #section5 .fp-tableCell {
    height: auto !important;
  }
  #section5 {
    height: auto !important;
  }
  
  #fp-nav {
    top: 55%;
    ul {
      li {
        margin: 7px 7px 10px;
        a {
          span {
            background: #000;
          }
        }
      }
    }
    &.right {
      right: 64px;
      @media #{$md-layout} {
        right: 10px;
      }
      @media #{$sm-layout} {
        right: 25px;
      }
    }
  }
  
  .fullpage-slider-mrg {
    margin-top: -34px;
    @media #{$xs-layout} {
      margin-top: 0;
      margin-bottom: 30px;
    }
  }
  .fullpage-slider-mrg-2 {
    margin-bottom: -88px;
    @media #{$xs-layout} {
      margin-bottom: 30px;
    }
    @media #{$sm-layout} {
      margin-bottom: -50px;
    }
  }
  
  .slider-section.active {
    .slider-animated-1 {
      h3 {
        -webkit-animation-delay: 0.5s;
        animation-delay: 0.5s;
        -webkit-animation-name: fadeInUp;
        animation-name: fadeInUp;
      }
      h1 {
        -webkit-animation-delay: 1s;
        animation-delay: 1s;
        -webkit-animation-name: fadeInUp;
        animation-name: fadeInUp;
      }
      a {
        -webkit-animation-delay: 1.3s;
        animation-delay: 1.3s;
        -webkit-animation-name: fadeInUp;
        animation-name: fadeInUp;
      }
      & img {
        -webkit-animation-delay: 0.8s;
        animation-delay: 0.8s;
        -webkit-animation-name: fadeInUp;
        animation-name: fadeInUp;
      }
    }
  }
  
  .slider12-img-2 {
    @media #{$xs-layout} {
      margin-top: 30px;
      margin-bottom: 30px;
    }
    img {
      width: 100%;
    }
  }
  
  .slider12-img-1 {
    margin: 0 4px 0 6px;
    img {
      width: 100%;
    }
  }
  
  @media #{$xx-layout} {
    .slider12-img-1 {
      text-align: center;
      img {
        width: 350px;
      }
    }
  }
  
  @media #{$xl-layout} {
    .slider12-img-1 {
      text-align: center;
      img {
        width: 350px;
      }
    }
    .slider12-img-2 {
      text-align: center;
      img {
        width: 420px;
      }
    }
  }
  
  @media #{$lg-layout} {
    .slider12-img-1 {
      text-align: center;
      img {
        width: 330px;
      }
    }
  }
  
  @media #{$xs-layout} {
    .fullpage-wrapper {
      padding-top: 130px;
      @media #{$sm-layout} {
        padding-top: 0px;
      }
    }
    .fullpage-slider-wrap-mrg {
      margin-bottom: 30px;
    }
  }
  
  @media #{$sm-layout} {
    .fullpage-slider-wrap-mrg {
      margin-bottom: 0px;
    }
  }
  
  /* home 13 */
  
  .slider-content-13 {
    h5 {
      letter-spacing: 1px;
      font-size: 24px;
      font-weight: 500;
      margin: 0;
      @media #{$xs-layout} {
        font-size: 22px;
      }
    }
    h1 {
      font-size: 120px;
      font-weight: 600;
      line-height: 120px;
      margin: 34px 0 55px;
      @media #{$lg-layout} {
        font-size: 90px;
        line-height: 90px;
        margin: 24px 0 35px;
      }
      @media #{$md-layout} {
        font-size: 80px;
        line-height: 90px;
        margin: 20px 0 30px;
      }
      @media #{$xs-layout} {
        font-size: 40px;
        line-height: 50px;
        margin: 15px 0 20px;
      }
    }
    .slider-btn {
      a {
        font-size: 16px;
        color: #010101;
        display: inline-block;
        border: 1px solid #333;
        text-transform: uppercase;
        line-height: 1;
        padding: 19px 50px 19px;
        &:hover {
          color: #fff;
          border: 1px solid #a749ff;
        }
      }
    }
  }
  
  @media #{$xs-layout} {
    .hm-13-slider {
      background-position: 72%;
    }
  }
  
  @media #{$sm-layout} {
    .hm-13-slider {
      background-position: 55%;
    }
  }
  
  .slider-single-img-14 {
    padding-bottom: 72px;
    margin: 0 23px;
    position: relative;
    z-index: 9;
    @media #{$lg-layout} {
      padding-bottom: 60px;
    }
    @media #{$md-layout} {
      padding-bottom: 44px;
    }
    @media #{$xs-layout} {
      padding-bottom: 0px;
      margin: 0 0px;
      text-align: center;
    }
    @media #{$sm-layout} {
      padding-bottom: 38px;
      text-align: inherit;
    }
    img {
      width: 100%;
    }
  }
  
  .slider-height-14 {
    height: 850px;
    padding: 89px 0 0;
    @media #{$lg-layout} {
      height: 715px;
    }
    @media #{$md-layout} {
      height: 525px;
      padding: 70px 0 0;
    }
    @media #{$xs-layout} {
      height: auto;
      padding: 120px 0 0;
    }
  }
  
  .slider-content-14 {
    margin: -90px 0 0;
    @media #{$xs-layout} {
      margin: 0px 0 30px;
      text-align: center;
    }
    @media #{$sm-layout} {
      margin: 0px 0 30px;
      text-align: left;
    }
    h3 {
      color: #fff;
      font-weight: 500;
      font-size: 24px;
      margin: 0 0 0 150px;
      position: relative;
      letter-spacing: 0.8px;
      @media #{$md-layout} {
        font-size: 20px;
      }
      @media #{$xs-layout} {
        font-size: 20px;
        margin: 0 0 0 100px;
      }
      @media #{$sm-layout} {
        margin: 0 0 0 60px;
      }
      &::before {
        position: absolute;
        left: -135px;
        top: 14px;
        content: "";
        width: 120px;
        height: 2px;
        background-color: #fff;
        @media #{$xs-layout} {
          left: -100px;
          width: 80px;
        }
        @media #{$xs-layout} {
          left: -60px;
          width: 50px;
        }
      }
    }
    h1 {
      color: #fff;
      font-size: 72px;
      line-height: 97px;
      margin: 14px 0 38px;
      @media #{$lg-layout} {
        font-size: 55px;
        line-height: 75px;
        margin: 10px 0 25px;
      }
      @media #{$md-layout} {
        font-size: 42px;
        line-height: 60px;
        margin: 10px 0 25px;
      }
      @media #{$xs-layout} {
        font-size: 33px;
        line-height: 50px;
        margin: 5px 0 15px;
      }
      @media #{$sm-layout} {
        font-size: 30px;
        line-height: 40px;
      }
    }
    .slider-btn {
      a {
        font-size: 16px;
        color: #010101;
        display: inline-block;
        text-transform: uppercase;
        line-height: 1;
        padding: 20px 50px 19px;
        border: none;
        background-color: #eeeff1;
        transition: all 0.4s ease-in-out 0s;
        z-index: 1;
        @media #{$xs-layout} {
          padding: 16px 30px 15px;
          font-size: 15px;
        }
        &:hover {
          color: #fff;
        }
      }
    }
  }
  
  .slider-content-15 {
    h1 {
      color: #fff;
      font-size: 90px;
      font-family: $abrilfatface;
      line-height: 115px;
      letter-spacing: 1px;
      margin: 0;
      @media #{$xl-layout} {
        font-size: 75px;
        line-height: 100px;
      }
      @media #{$lg-layout} {
        font-size: 70px;
        line-height: 85px;
      }
      @media #{$md-layout} {
        font-size: 60px;
        line-height: 85px;
      }
      @media #{$xs-layout} {
        font-size: 38px;
        line-height: 55px;
      }
      @media #{$sm-layout} {
        font-size: 42px;
        line-height: 65px;
      }
    }
    &.slider15-mrg-nrg {
      h1 {
        margin-top: -26px;
        @media #{$lg-layout} {
          margin-top: -18px;
        }
        @media #{$xs-layout} {
          margin-top: -20px;
        }
      }
    }
    .dealy-style-2 {
      margin: 49px 0 43px;
      @media #{$lg-layout} {
        margin: 40px 0 40px;
      }
      @media #{$md-layout} {
        margin: 30px 0 35px;
      }
      @media #{$xs-layout} {
        margin: 20px 0 5px;
      }
      span {
        display: inline-block;
        font-size: 48px;
        font-weight: 300;
        color: #fff;
        margin: 0 34px 0;
        @media #{$lg-layout} {
          margin: 0 25px 0;
        }
        @media #{$md-layout} {
          margin: 0 20px 0;
          font-size: 40px;
        }
        @media #{$xs-layout} {
          margin: 0 20px 20px;
          font-size: 30px;
        }
        p {
          font-size: 18px;
          font-weight: 500;
          margin: 33px 0 0;
          color: #fff;
          text-transform: uppercase;
          @media #{$md-layout} {
            margin: 20px 0 0;
          }
          @media #{$xs-layout} {
            margin: 10px 0 0;
          }
        }
      }
    }
    .slider-btn-12 {
      a {
        display: inline-block;
        background-color: #e90042;
        border: 1px solid #e90042;
        color: #fff;
        text-transform: uppercase;
        line-height: 1;
        font-size: 16px;
        padding: 17px 47px 19px;
        z-index: 1;
        @media #{$xs-layout} {
          padding: 13px 30px 15px;
        }
        &:hover {
          border: 1px solid $theme-color;
        }
      }
    }
  }
  
  .slider-height-18 {
    height: 460px;
    @media #{$xs-layout} {
      background-position: 85%;
    }
    &.slider-overly-res {
      @media #{$xs-layout} {
        height: 400px;
        position: relative;
        &:before {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          content: "";
          background-color: #fff;
          opacity: 0.5;
        }
      }
    }
  }
  
  .slider-banner-area {
    margin-top: 163px;
    @media #{$md-layout} {
      margin-top: 0;
    }
    @media #{$xs-layout} {
      margin-top: 0;
    }
  }
  @media #{$md-layout} {
    .res-mrg-md-mb {
      margin-bottom: 30px;
    }
  }
  @media #{$xs-layout} {
    .res-mrg-md-mb {
      margin-bottom: 30px;
    }
  }
  
  .ht-swiper-button-nav {
    &:after {
      font-size: 0;
    }
  }
  
  .slider-active-3 {
    .ht-swiper-button-nav {
      background: none;
      border: none;
      padding: 0;
      font-size: 40px;
      color: #333;
      visibility: hidden;
      opacity: 0;
    }
  
    &:hover {
      .ht-swiper-button-nav {
        visibility: visible;
        opacity: 1;
      }
    }
  }
  