
.main-div-be-part {
  display: flex;
  flex-direction: column;
  padding: 4rem;
  @media (max-width: 768px) {
    padding-right: 0;
    padding-left: 0;
     
   }
  .title {
    text-align: center;
    width: 100%;
    h3 {
      color: $theme-color;
      font-size: 30px;
    }
    p {
      font-size: 20px;
      margin-top: 2rem;
    }
  }

  .be-part-collapsible {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 3rem;
    @media (max-width: 768px) {
      padding-right: 2rem;
      padding-left: 2rem;
       
     }
    .Collapsible {
      width: 100%;
      border-bottom: 1px solid $theme-color;
      padding: 1rem;
      padding-left: 0px;
      font-size: 1.2rem;
      position: relative;
      p {
        font-size: 18px;
        line-height: 25px;
        color: $theme-color;
      }
    }
    .Collapsible__trigger {
      font-size: 1rem;
      &:before {
        font-family: "FontAwesome";
        content: "\f107";
        color: $theme-color;
        font-size: 16px;
        right: 10px;
        position: absolute;
        top: 15px;
        display: block;
        transition: transform 300ms;
      }
      &.is-open {
        &:before {
          transform: rotateZ(180deg);
        }
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
  .events-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 3rem;
    width:100%;
    .event-container {
      padding: 1rem;
      border: 2.42px solid $theme-color;
      width: auto;
      padding-top: 1rem;
      padding-bottom: 1rem;
      margin-bottom: 1rem;
      width:100%;
      a {
        color: black !important;
      }
      .event-row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: baseline;
        justify-content: start;
        align-items: end;
        img {
          margin-bottom: 0;
          width: 23.333333rem;
        }
        @media (max-width: 768px) {
          img{
            width: 100%;
          }
          width: 100%;
          flex-direction: column;
        }

        .event-text {
          display: flex;
          flex-direction: column;
          justify-content: start;
          align-items: start;
          margin-left: 1rem;
          h3 {
            margin: 0px;
            work-break: break-all;
            margin-bottom: 0px;
            margin-top: 0px;
            padding-top: 0px;
          }
          h4 {
            margin: 0px;
            work-break: break-all;
            margin-bottom: 0px;
            margin-top: 0px;
            padding-top: 0px;
          }
          h5 {
            margin: 0px;
            work-break: break-all;
            margin-bottom: 0.5rem;
            margin-top: 0.5rem;
            padding-top: 0px;
            img {
              display: none;
            }
          }
          p {
            font-size: 1.2em;
            margin: 0px;
            work-break: break-all;
            margin-bottom: 0.5rem;
            margin-top: 0.5rem;
            padding-top: 0px;
            text-align: justify;
            color: #000;
          }
          #description {
            height: 35rem;
            overflow: auto;
          }
          @media (max-width: 768px) {
           margin-left:0;
            h3 {
             width: 100%;
              margin-top: 1rem;
              text-align:center;
            }
            p {
              text-align:center;
              width: 100%;
            }
            h5 {
              text-align:center;
              align-self: flex-start !important;
              width: 100%;
              flex-wrap: wrap !important;
            }
          }
        }
      }
    }
  }
}
