.main-div-profile {
  display: flex;
  flex-direction: column;
  padding: 2rem;

  .title {
    text-align: center;
    width: 100%;
    h3 {
      color: $theme-color;
      font-size: 30px;
    }
    p {
      font-size: 20px;
      margin-top: 2rem;
    }
  }

  .profile-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 3rem;
    #box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin-bottom: 2rem;
      @media #{$xs-layout} {
        width: 100%;
        
      }
    }
    
    .profile {
      .img-bg {
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.3s;
        text-align: center;
        width: 100%;
        height: 20rem;
        background-size: cover;
        border-radius: 10px;
        position: relative;
        background-position: center center;
        .text {
          display: none;
          position: absolute;
        }
        &:hover {
          .text {
            width: 100%;
            display: block;
          }
          &::before {
            content: "";
            position: absolute;

            top: 0px;
            right: 0px;
            bottom: 0px;
            opacity: 0.6;
            left: 0px;
            background-color: rgba(0, 141, 164, 1);
          }
        }
      }

      .text {
        padding: 1rem;
        h3 {
          font-size: 15px;
          margin-bottom: 2rem;
          color: #fff;
        }
        p {
          font-size: 18px;
          color: #fff;
        }
      }
      
      margin-top: 1rem;
      color: $theme-color;
      display: flex;
      flex-wrap: wrap;
      width: 25rem;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0.5rem;
      border: 2px solid $theme-color;
      border-radius: 10px;
      @media #{$xs-layout} {
        width: 100%;
        .img-bg {
          width: 100%;
          height: 25rem;
          background-size: cover;
          border-radius: 10px;
          background-position: center center;
          margin-top: 0rem;
        }
        .text {
          width: 100%;
        }
      }
    }
  }
}
